<template>
  <div class="page">
    <div class="page-header">
      <el-button
        class="footer-btn"
        type="primary"
        @click="importItem"
        v-has="'import'"
        >{{ $t('MAC.importConfigurationFile') }}</el-button
      >
      <el-button
        class="footer-btn"
        v-has="'export'"
        type="primary"
        plain
        icon="el-icon-upload2 iconfont icon-export"
        @click="exportAll"
        >{{ $t('MAC.export') }}</el-button
      >
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-plus iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('MAC.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
          <el-form-item class="page-tools-right" v-has="'list'">
            <select-model
              v-model="tabelSearch.model"
              selectAll
              @change="getTabelData"
            ></select-model>
          </el-form-item>
        </el-form>
      </div>
      <table-pagination
        memory="MAC"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('MAC.pushMacConfigurationFile')"
            placement="top"
            :open-delay="1000"
            v-has="'push'"
          >
            <el-button type="text" class="btn-p0" @click="pushItem(scope.row.id)">
              <i class="el-icon-s-promotion iconfont icon-push"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('MAC.downloadMacConfigurationFile')"
            placement="top"
            :open-delay="1000"
            v-has="'download'"
          >
            <el-button type="text" class="btn-p0" @click="downloadItem(scope.row.id)">
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
    <!-- 导入 -->
    <dialog-import-item
      :visible="importVisible"
      url="/tb-mac/uploadCFGFile"
      :title="$t('MAC.importConfigurationFile')"
      :fileFormat="['.xml', '.zip']"
      :fileSize="20"
      :fileTipText="$t('MAC.importFileTip')"
      @submit="getTabelData"
      msgTableShow
      :msgTable="importMsgTable"
      @msgTableExport="importMsgTableExport"
      @importSuccess="getTabelData"
      @close="importVisible = false"
      :typeErrMessage="$t('MAC.importFileTypeErrMessage')"
    ></dialog-import-item>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { TablePagination, DialogImportItem, SelectModel } from '@/components'
import {
  macSearch,
  removeMacBatch,
  downloadMac,
  macExport,
  macExportAll,
  macExportFailedData,
} from '@/api/mac'
import { pushConfigMac } from '@/api/setParam'
import { downloadFile } from '@/plugins/methods'

export default {
  name: 'MAC',
  components: {
    TablePagination,
    DialogImportItem,
    SelectModel,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: null,
        model: null,
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('MAC.macAddress'),
          prop: 'macAddress',
          minWidth: 200,
          sortable: 'custom',
          filter: 'toUpperCase',
        },
        {
          label: this.$t('MAC.model'),
          prop: 'model',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('MAC.firewareVersion'),
          prop: 'firmwareVersion',
          minWidth: 200,
          filterEnable: true,
        },
        {
          label: this.$t('MAC.fileName'),
          prop: 'fileName',
          minWidth: 200,
        },
        {
          label: this.$t('MAC.fileSize'),
          prop: 'fileSize',
          minWidth: 200,
          sortable: 'custom',
          filter: (val) => {
            return val + 'KB'
          },
        },
        {
          label: this.$t('MAC.site'),
          prop: 'siteName',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('dateModified'),
          prop: 'updateTime',
          minWidth: 200,
          sortable: 'custom',
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      tableSelection: [],
      // 导入
      importVisible: false,
      importMsgTable: {
        title: this.$t('MAC.importConfigurationFile'),
        columnData: [
          {
            label: this.$t('device.macAddress'),
            prop: 'macAddress',
            minWidth: 100,
            filter: 'toUpperCase',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState('memory', ['language']),
    ...mapGetters('session', ['pageTabelHeight']),
  },
  created() {
    this.checkLang()
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      macSearch(this.tabelSearch)
        .then((res) => {
          res.data.result.rows.map(item => {
            item.fileSize = item.fileSize === 0 
              ? 1
              : item.fileSize
          })
          this.tableData = res.data.result.rows // 表格数据
          this.tabelTotal = res.data.result.totalRows // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'updateTime'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 导入
    importItem() {
      this.importVisible = true
    },
    // 导入-导出错误信息
    importMsgTableExport(val) {
      macExportFailedData(val).then((res) => {
        downloadFile(res)
      })
      this.importVisible = false
    },
    // 导出
    exportItem() {
      const ids = this.tableSelection.map((e) => e.id)
      macExport(ids).then((res) => {
        downloadFile(res)
      })
    },
    exportAll() {
      macExportAll([]).then((res) => {
        console.log('下载地址', res)
        downloadFile(res)
      })
    },
    // 下载
    downloadItem(id) {
      downloadMac(id).then((res) => downloadFile(res))
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('MAC.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id),
            args = this.tableSelection.map((e) => e.macAddress)
          removeMacBatch({ ids, args })
            .then(({ data }) => {
              this.$message.success(data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 推送任务
    pushItem(id) {
      pushConfigMac({ id })
        .then(({ data }) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
    // 检查语言
    checkLang() {
      console.log('语言', this.language)
      switch (this.language) {
        case 'en':
          this.importMsgTable.columnData.push({
            label: this.$t('failedReason'),
            prop: 'reasonEn',
            minWidth: 200,
          })
          break
        default:
          this.importMsgTable.columnData.push({
            label: this.$t('failedReason'),
            prop: 'reason',
            minWidth: 200,
          })
          break
      }
    }
  },
}
</script>

<style lang="scss" scoped>
 
</style>
